import { change, Form, useDispatch, useSelector } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/base/Button';
import CircularProgress from '../../../components/base/CircularProgress';
import GridSplit from '../../../components/base/GridSplit';
import AsideBody from '../../../components/Space/Aside/Body';
import {
  addressesDetails as detailsMutation,
  addressesFindplacefromtext as geocodingMutation,
} from '../../../objects/addresses/mutations';
import placeData from '../../../objects/places/datas.json';
import { placesCreate as createMutation } from '../../../objects/places/mutations';
import spaceQuery from '../../../objects/spaces/queries/space.graphql';
import SpaceType from '../../../types/Space';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const PlaceCreatePage: FC<
  ResultProps & {
    formName?: string;
  }
> = ({ formName = 'place', params: { space: spaceId } }) => {
  const [geocodingMutationFunc] = useMutation(geocodingMutation);
  const [detailsMutationFunc] = useMutation(detailsMutation);
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(spaceQuery, {
    variables: {
      id: spaceId,
    },
  });

  const dispatch = useDispatch();
  const place = useSelector((state: any) =>
    state.form[formName] ? state.form[formName].values : {},
  );

  const handleGeocodingOnClick = async () => {
    const result = await geocodingMutationFunc({
      variables: {
        address: `${place.name} ${place.formattedAddress}`,
      },
    });

    if (result && !result.errors) {
      if ('OK' === result.data.addressesFindplacefromtext.status) {
        const [{ place_id: placeId }] =
          result.data.addressesFindplacefromtext.candidates;

        const details = await detailsMutationFunc({
          variables: {
            placeId,
          },
        });

        if (details && !details.errors) {
          if ('OK' === details.data.addressesDetails.status) {
            const {
              result: {
                name,
                formatted_address: formattedAddress,
                geometry: {
                  location: { lat, lng },
                },
              },
            } = details.data.addressesDetails;

            if (name) {
              dispatch(change(formName, 'name', name));
            }
            if (formattedAddress) {
              dispatch(change(formName, 'formattedAddress', formattedAddress));
            }
            dispatch(change(formName, 'latitude', lat));
            dispatch(change(formName, 'longitude', lng));
          }
        }
      }
    }
  };

  const handleOnSubmit = async (values: any) => {
    const result = await createMutationFunc({
      variables: {
        data: {
          ...values,
          spaceId,
        },
      },
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const placeId = result.data.placesCreate.id;
      window.history.replaceState(
        {
          placeId,
          spaceId,
        },
        '',
        `/admin/spaces/update/${spaceId}/?tab=1`,
      );
      navigate(`/admin/places/update/${placeId}/`);
    }
  };

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const space: SpaceType = data && (data.space as SpaceType);

  if (!space) {
    return null;
  }

  return (
    <GridSplit
      aside={{
        body: place && <AsideBody places={[place]} zoom={15} />,
        className: 'sticky top-0 self-start',
        width: '40%',
      }}
      header={{
        actions: (
          <Button
            onClick={handleGeocodingOnClick}
            size="small"
            status="secondary"
          >
            Géocoder
          </Button>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: `Création d'une adresse`,
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={placeData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: 'Créer cette adresse',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(PlaceCreatePage), {
  admin: true,
  layout: true,
});
